import './components/jquery.responsifyBgImg';
import './components/checkflexgap';
import './components/isInViewport';
import './vendor/slick.min';
import './vendor/lity.min';

import SimpleBar from 'simplebar';
import {runAnimations} from './components/countup';

(function($) {

    const $body = $('body');
    let submenuDisplay;
    let menuDisplay;
    let winW  = $(window).width();

    let animationRun = false;

	// Offcanvas

    $('body').on('click', '.js-toggle-menu', function() {
        let $this = $(this);

        if ($this.data('action') == 'swap') {
            hideMenu();
            displayMenu($this);
        }

        if ($this.data('action') == 'toggle') {
            if (menuDisplay == true) {
                hideMenu();
            } else {
                displayMenu($this);
            }
        }
        
    });

    function displayMenu(el) {
        let panelid = el.data('panel-id');
        let $panel = $(panelid);

        $panel.addClass('menu-open');
        menuDisplay = true;
    }

    function hideMenu() {
        $('.menu-panel').removeClass('menu-open');
        $('.menu-panel').removeClass('menu-submenu-open');

        $('.menu-panel').removeClass(function (index, css) {
            return (css.match (/\bmenu-submenu-open\S+/g) || []).join(' '); // removes anything that starts with "menu-submenu-open"
        });

        $('.sub-menu').removeClass('sub-menu--active');
        menuDisplay = false;
    }

    // Flyout Submenu

    let currentLevel = 0;
    let newLevel;

    if (winW > 1023) {
        $('body').on('mouseover', '.menu-item-has-children', function(e) {
            if(!$(this).find('> .sub-menu').hasClass('sub-menu--active')) {
                e.stopPropagation();
                newLevel = $(this).data('level');
                setLevel(true);

                if (newLevel == currentLevel) {
                    $('.sub-menu.level-'+newLevel).removeClass('sub-menu--active');
                    currentLevel = newLevel;
                }

                if (newLevel < currentLevel) {
                    $('.sub-menu').removeClass('sub-menu--active');
                    $('.menu-panel').removeClass('menu-submenu-open--level' + currentLevel);
                    currentLevel = newLevel;

                    setLevel(true);
                } 

                if (newLevel > currentLevel) {
                    currentLevel = newLevel;
                    setLevel(true);
                }
                
                $(this).find('> .sub-menu').addClass('sub-menu--active');
                $(this).closest('#menu-panel--nav').addClass('menu-submenu-open menu-submenu-open--level'+ currentLevel);
            }
        });

        $('body').on('mouseover', '.menu-item:not(.menu-item-has-children)', function(e) {
            e.stopPropagation();
            newLevel = $(this).data('level');

            if (newLevel <= currentLevel) {
                currentLevel = newLevel;
                setLevel(false);

                $('.menu-panel').removeClass('menu-submenu-open--level' + currentLevel);
                // $('.menu-panel').removeClass(function (index, css) {
                //     return (css.match (/\bmenu-submenu-open\S+/g) || []).join(' '); // removes anything that starts with "menu-submenu-open"
                // });
           
                $('.sub-menu.level-'+currentLevel).removeClass('sub-menu--active');

               
            } else {
                currentLevel = newLevel;
                setLevel(false);
            }
        });
    }

    function setLevel(hasChildren) {
        if (hasChildren == true) {
            $('#newlevel').addClass('red').find('> span').html(newLevel);
            $('#currentlevel').addClass('red').find('> span').html(currentLevel);
        } else {
            $('#newlevel').removeClass('red').find('> span').html(newLevel);
            $('#currentlevel').removeClass('red').find('> span').html(currentLevel);
        }
        
    }

    setLevel();
    

    // Offcanvas Submenu
    if (winW < 1024) {

        $('body').on('click', '.js-submenu', function(e) {

            e.preventDefault();
            e.stopPropagation();
            let $this = $(this);
            let action = $this.data('action');
            let level = $this.data('level');
            let current = $this.data('current');

            if (submenuDisplay == true && action == 'close') {
                hideSubMenu(current);
            }

            if (action == 'open') {
                displaySubMenu($this, level);
            }
        });

        function displaySubMenu(el, lvl) {
            $body.addClass('submenu-open');
            el.parent().next('.sub-menu').toggleClass('sub-menu--active');
            $('#submenu-close').data('current', lvl);
            console.log($('#submenu-close').data('current'));
            submenuDisplay = true;
        }

        function hideSubMenu(lvl) {
            if (lvl == 0) {
                $body.removeClass('submenu-open');
                submenuDisplay = false;
            } 

            $('.sub-menu.level-' + lvl).removeClass('sub-menu--active');
            $('#submenu-close').data('current', lvl - 1);
            console.log($('#submenu-close').data('current'));
        }
    }

    // Search toggle

    $('body').on('click', '.js-toggle-search', () => {
        $body.toggleClass('search-active');
    });

   
    // Slick Slider

    if($('.slick-slider--offset-carousel').length>0) {

        $.each( $('.slick-slider--offset-carousel'), function( key, value ) {

            var slideCount = $(this).children().length;

            if (slideCount <= 4) {
                // clone element
                $(this).children().clone(true, true).appendTo($(this));
            }
        });
    }

    let slideCount768, slideCount1200, slideCount1400, slideCount1600;

    if($('.slick-slider--offset-carousel').hasClass('type1')) {
        slideCount768 = 3;
        slideCount1200 = 4;
        slideCount1400 = 4;
        slideCount1600 = 5;
    } else {
        slideCount768 = 2;
        slideCount1200 = 3;
        slideCount1400 = 3;
        slideCount1600 = 4;
    }

    $('.slick-slider--offset-carousel').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        prevArrow: '.offset-carousel__prev.carousel-nav__prev',
        nextArrow: '.offset-carousel__next.carousel-nav__next',
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: slideCount1600,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: slideCount1400,
                    slidesToScroll: 1,
                    infinite: true,
                }
              },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slideCount1200,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: slideCount768,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
       
    });

    $('.offset-carousel__prev.carousel-nav__prev').on('click', function(){
        $('.slick-slider--offset-carousel').slick('slickPrev');
    });

    $('.offset-carousel__next.carousel-nav__next').on('click', function(){
        $('.slick-slider--offset-carousel').slick('slickNext');
    });

    // Vertical Carousel

    $('.slick-slider--vertical-profile-carousel').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        prevArrow: '.vertical-profile-carousel__prev.carousel-nav__prev',
        nextArrow: '.vertical-profile-carousel__next.carousel-nav__next',
        mobileFirst: true,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 2,
        slidesToScroll: 1
       
    });

    $('.vertical-profile-carousel__prev.carousel-nav__prev').on('click', function(){

        $('.slick-slider--vertical-profile-carousel').slick('slickPrev');
    });
    
    $('.vertical-profile-carousel__next.carousel-nav__next').on('click', function(){
        $('.slick-slider--vertical-profile-carousel').slick('slickNext');
    });

    // Horizontal Carousel

    $('.slick-slider--horizontal-profile-carousel').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        prevArrow: '.horizontal-profile-carousel__prev.carousel-nav__prev',
        nextArrow: '.horizontal-profile-carousel__next.carousel-nav__next',
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
       
    });

    $('.horizontal-profile-carousel__prev.carousel-nav__prev').on('click', function(){

        $('.slick-slider--horizontal-profile-carousel').slick('slickPrev');
    });
    
    $('.horizontal-profile-carousel__next.carousel-nav__next').on('click', function(){
        $('.slick-slider--horizontal-profile-carousel').slick('slickNext');
    });

    // Case Studies Carousel

    $('.slick-slider--cs-carousel').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        prevArrow: '.cs-carousel__prev.carousel-nav__prev',
        nextArrow: '.cs-carousel__next.carousel-nav__next',
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1
       
    });

    $('.cs-carousel__prev.carousel-nav__prev').on('click', function(){

        $('.slick-slider--cs-carousel').slick('slickPrev');
    });
    
    $('.cs-carousel__next.carousel-nav__next').on('click', function(){
        $('.slick-slider--cs-carousel').slick('slickNext');
    });

    // Case Studies Carousel - Centered

    $('.slick-slider--centered').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
        speed: 300,
        prevArrow: '.carousel-nav--centered__prev',
        nextArrow: '.carousel-nav--centered__next',
        mobileFirst: true,
        slidesToScroll: 1
       
    });

    $('.carousel-nav--centered__prev').on('click', function(){
        let slider = $(this).data('slider');
        $(slider).slick('slickPrev');
    });
    
    $('.carousel-nav--centered__next').on('click', function(){
        let slider = $(this).data('slider');
        $(slider).slick('slickNext');
    });

    $('.slick-slider--testimonial-carousel').slick({
 
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 7000,
        prevArrow: '.testimonial-carousel__prev',
        nextArrow: '.testimonial-carousel__next'
       
    });

    // Scroll to ID

    $('.js-scrollto').on('click', function () {
        var id = $(this).data('target');
        scrollTo(id);
    });

    function scrollTo(id){
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === 'top') {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top;
        }

        $('html,body').animate({scrollTop: scrollPos},'slow');
    }

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

    // Animate on Scroll
    $('.js-visible:in-viewport(0)').addClass('is-visible');

    $(window).on('scroll', function() {
        $('.js-visible:in-viewport(-250)').addClass('is-visible');  
        if ($('.stat-counter').hasClass('is-visible') && animationRun === false) {
            runAnimations();
            animationRun = true;
        }
    });

	// Footcols

    $('.site-footer__column__heading').on('click', function () {
        $(this).parent('.site-footer__column').toggleClass('open');
    });

    $('.js-highlight').on('click', function() {
       let target = $(this).data('target');

       let $el = $(target);

        $el.addClass('pulse-small');

        setTimeout(function() {
            $el.removeClass('pulse-small');
        },300);
    });

    // Tabs

    $('.js-tab').on('click', function() {
        var $this = $(this);
        if (!$this.hasClass('tabbed-panels__tab--active')) {
            $('.tabbed-panels__tab--active').removeClass('tabbed-panels__tab--active');
            $('.tabbed-panels__item--active').removeClass('tabbed-panels__item--active is-visible');
            var target = $this.data('target');
            var tab_index = $this.data('tab-index');

            if(!tab_index && tab_index != 0) {
                $this.addClass('tabbed-panels__tab--active');
            } else {
                var i = tab_index;
                $('.tabbed-panels__tab').eq(i).addClass('tabbed-panels__tab--active');
            }
            
            changePanel(target);
        }
    });

    function changePanel(panel) {
        
        $(panel).addClass('tabbed-panels__item--active');
        
        setTimeout(function() {
            $(panel).addClass('is-visible');
        },200);
    }
    
    // Custom Scroll Bar

    let $scrollbox = $('.scrollbox');

    if ($scrollbox.length > 0) {
        $.each( $scrollbox, function( index ) {

            new SimpleBar($scrollbox[index], {
                scrollbarMaxSize: 300
            });
        });
    }

    // Generic Toggles

    $('.js-toggle').on('click',function() {
        let $this = $(this); 
        let target = $this.data('target');
        let type = $this.data('toggle');

        if(!type) {
            if (target == 'self') {
                $this.toggleClass('active');
            } else {
                let $target = $(target);
                $target.toggleClass('active');
            }
        } else {
            if (type == 'faq') {
                let $target = $(target);
                $this.parent($target).toggleClass('is-visible');
            }
        }
    });

    // Dropdown List

    $('body').on('click', '.js-dropdown-list', function() {
     
        var $this = $(this);

        $this.parent().toggleClass('dropdown-list__item--is-open');
    });

    $('.js-readmore__button').on('click',function() {
        var $textel = $(this).find('.readmore__link__text');

        ($textel.text() == 'Read More') ? $textel.text('Read Less').parent().attr('title', 'Read Less') : $textel.text('Read More').parent().attr('title', 'Read More');

        $(this).parent().prev('.js-readmore__container').toggleClass('open');
    });

})(jQuery);